<template>
    <div v-if="authFrameLoaded" :display="isIframeRoute ? 'block' : 'none'" h-full w-full>
        <div
            v-for="i in iframeVINList"
            :key="i"
            :style="{ display: i === calmelCaserouteKey ? 'block' : 'none' }"
            h-full
            w-full
        >
            <n-spin h-full w-full :show="getLoading(i)">
                <div w-full h-full>
                    <iframe
                        :ref="(v) => setRef(v as Element, i)"
                        :src="getSrc(i)"
                        frameborder="0"
                        w-full
                        h-full
                    />
                </div>
            </n-spin>
        </div>
    </div>
</template>

<script setup lang="ts">
import camelcase from 'camelcase';
import decamelize from 'decamelize';
import type { ComponentPublicInstance } from 'vue';
import { normalizePath, routeMap } from '../utils/router';
import { useCommonStore } from '~/stores/common';
import { useUserStore } from '~/stores/user';
import { useAuthToken } from '~/utils/token';

const { authToken } = useAuthToken();
const { locale } = useTypedI18n();
const commonStore = useCommonStore();
const { currentRouteKey } = storeToRefs(commonStore);
const calmelCaserouteKey = computed(() => {
    const a = camelcase(currentRouteKey.value.replaceAll('/', '-'), {
        pascalCase: true,
        preserveConsecutiveUppercase: true,
    });
    return a;
});

const router = useRouter();
const route = useRoute();
const isIframeRoute = computed(() =>
    ['/info/document/eqmaintain', '/info/document/interterminal'].some((i) =>
        router.currentRoute.value.path.includes(i),
    ),
);

const userStore = useUserStore();
const authFrameLoaded = ref(true);

const iframeVINList = computed(() => commonStore.iframeVINList);
const iframeMap = ref(new Map<string, HTMLIFrameElement>());
const loadingMap = ref(new Map<string, boolean>());
const authFrame = ref<HTMLIFrameElement | null>(null);
const getMessageData = ref<any>(null);

// const getMessage = (event) => {
//     getMessageData.value = event.data;
//     router.push(getMessageData.value.targetPath);
// };

const langChange = () => {
    switch (locale.value) {
    case Locales.csCZ:
        return 'cs-CZ';
    case Locales.deDE:
        return 'de-DE';
    case Locales.enUS:
        return 'en-US';
    case Locales.esES:
        return 'es-ES';
    case Locales.frFR:
        return 'fr-FR';
    case Locales.huHU:
        return 'hu-HU';
    case Locales.itIT:
        return 'it-IT';
    case Locales.nlNL:
        return 'nl-NL';
    case Locales.plPL:
        return 'pl-PL';
    case Locales.ptPT:
        return 'pt-PT';
    case Locales.ruRU:
        return 'ru-RU';
    case Locales.trTR:
        return 'tr-TR';
    case Locales.viVN:
        return 'vi-VN';
    case Locales.zhCN:
        return 'zh-CN';
    default:
        return 'zh-CN';
    }
};

const getmsgLoad = (event) => {
    if (event.data?.action === 'tokenExpired') {
        location.reload(); // 刷新页面
    }

    if (event.data?.action === 'msgLoad') {
        const lang = langChange();
        const token = authToken.value;
        iframeMap.value.forEach((iframeRef) => {
            if (iframeRef?.contentWindow) {
                const message = {
                    action: 'langVinChange',
                    opParams: { language: lang, token },
                };
                iframeRef.contentWindow.postMessage(message, '*');
            } else {
                console.error(`iframe ${iframeRef} not ready`);
            }
        });
    }
};

onMounted(() => {
    window.addEventListener('message', getmsgLoad);
});

onBeforeUnmount(() => {
    window.removeEventListener('message', getmsgLoad);
});

const buildQueryString = (params: any) => {
    return Object.entries(params)
        .map(([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
};

const srcUrl = ref<any>(null);

// const languageType = ref<string>('zh-CN');

const getSrc = (name: string) => {
    const item = routeMap.get(normalizePath(decamelize(name, { separator: '/' })));
    const lang = langChange();
    // const loginChannel = 'SSNW';
    const token = authToken.value;
    if (item && item.isFrame === '1') {
        srcUrl.value = item.component;
        return `${item.component}${
            item.component?.charAt(item.component?.length - 1) === '?'
                ? `token=${token}`
                : `?token=${token}`
        }`;
    }
    return '';
};

watch(
    () => commonStore.locale,
    (newLocale) => {
        const lang = langChange();
        const token = authToken.value;
        iframeMap.value.forEach((iframeRef) => {
            if (iframeRef?.contentWindow) {
                const message = {
                    action: 'langVinChange',
                    opParams: { language: lang, token },
                };
                iframeRef.contentWindow.postMessage(message, '*');
            } else {
                console.error(`iframe ${iframeRef} not ready`);
            }
        });
    },
);

const setRef = (iframeRef: Element | ComponentPublicInstance | null, iframeName: string) => {
    if (iframeRef) {
        iframeMap.value.set(iframeName, iframeRef as HTMLIFrameElement);
        if (!iframeMap.value.has(iframeName)) {
            loadingMap.value.set(iframeName, true);
        }
        (iframeRef as HTMLIFrameElement).onload = () => {
            loadingMap.value.set(iframeName, false);
        };
    }
};

const getLoading = (name: string) => {
    const loading = loadingMap.value.get(name);
    return loading ?? true;
};

watchArray(
    () => [...iframeVINList.value],
    (v, oldV, added, removed) => {
        removed.forEach((i) => {
            loadingMap.value.set(i, true);
        });
    },
);
</script>

<style scoped>
:deep(.n-spin-content) {
    height: 100%;
}
</style>
