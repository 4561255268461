export default {
    // 中
    zhCN: {
        fill_in_at_least_one_query_condition: '至少填写一个查询条件',
        reporting_organization_name: '上报组织名称',
        full_query: '全量查询',
        query_time: '查询时间',
        organization_code: '组织CODE',
        organization_name: '组织名称',
        country: '国家',
        number_of_cases: '案例总数',
        timely_reply_to_the_number_of_cases: '及时回复案例数量',
        prompt_response_percentage: '及时回复百分比',
        the_number_of_cases_was_not_timely_replied: '未及时回复案例数量',
        percentage_not_responding_in_time: '未及时回复百分比',
        parts_list: '零件清单',
        parts_num: '零件数量',
        sales_country: '销售国家',
        continuous_interval: '连续区间',
        cycle: '周期',
        growth_threshold: '增长率监控阈值',
        tech_inquiry_check: '关联技术问询清单',
        failed_part: '失效零件',
        symptoms: '故障现象',
        fault_cause: '故障原因',
        closing_statement: '结案说明',
        invoice_number: '单据号',
        primary_system: '一级系统',
        secondary_system: '二级系统',
    },
    // 英
    enUS: {
        fill_in_at_least_one_query_condition: 'Fill in at least one query condition',
        reporting_organization_name: 'Reporting organization name',
        full_query: 'Full query',
        query_time: 'Query time',
        organization_code: 'Organization code',
        organization_name: 'Organization name',
        country: 'Country',
        number_of_cases: 'Number of cases',
        timely_reply_to_the_number_of_cases: 'Timely reply to the number of cases',
        prompt_response_percentage: 'Prompt response percentage',
        the_number_of_cases_was_not_timely_replied: 'The number of cases was not timely replied',
        percentage_not_responding_in_time: 'Percentage not responding in time',
        parts_list: 'Parts List',
        parts_num: 'Part Quantity',
        sales_country: 'Sales Country',
        continuous_interval: 'Continuous Interval',
        cycle: 'Cycle',
        growth_threshold: 'Growth Threshold',
        tech_inquiry_check: 'Tech Inquiry Checklist',
        failed_part: 'System-part',
        symptoms: 'Fault phenomenon',
        fault_cause: 'Fault cause',
        closing_statement: 'Closing statement',
        invoice_number: 'Invoice Number',
        primary_system: 'Primary System',
        secondary_system: 'Secondary System',
    },
    // 德
    deDE: {
        fill_in_at_least_one_query_condition: 'Füllen Sie mindestens eine Abfragebedingung aus',
        reporting_organization_name: 'Name der meldenden Organisation',
        full_query: 'Vollständige Abfrage',
        query_time: 'Abfragezeit',
        organization_code: 'Organisationscode',
        organization_name: 'Organisationsname',
        country: 'Land',
        number_of_cases: 'Gesamtzahl der Fälle',
        timely_reply_to_the_number_of_cases: 'Rechtzeitige Reaktion auf die Anzahl der Fälle',
        prompt_response_percentage: 'Prozentwert rechtzeitig wiederhergestellt',
        the_number_of_cases_was_not_timely_replied:
            'Wird die anzahl der fälle nicht rechtzeitig gemeldet',
        percentage_not_responding_in_time: 'Wurde nicht rechtzeitig über die prozent berichtet',
        parts_list: 'Teilliste', // 德语
        parts_num: 'Anzahl der Teile',
        sales_country: 'Verkaufsland',
        continuous_interval: 'Kontinuierliches Intervall',
        cycle: 'Zyklus',
        growth_threshold: 'Wachstumsschwelle',
        tech_inquiry_check: 'Tech-Anfrage-Checkliste',
        failed_part: 'Defekte Teile',
        symptoms: 'Fehlerphänomen',
        fault_cause: 'Fehlerursache',
        closing_statement: 'Akte geschlossen',
        invoice_number: 'Rechnungsnummer',
        primary_system: 'Primary System',
        secondary_system: 'Sekundäres System',
    },
    // 法
    frFR: {
        fill_in_at_least_one_query_condition: 'Remplissez au moins une condition de demande',
        reporting_organization_name: "Nom de l'organisation de rapport",
        full_query: 'Requête complète',
        query_time: 'Temps de requête',
        organization_code: "Code d'organisation",
        organization_name: "Nom d'organisation",
        country: 'Pays',
        number_of_cases: 'Nombre total de cas',
        timely_reply_to_the_number_of_cases: 'Réponse rapide au nombre de cas',
        prompt_response_percentage: 'Pourcentage de réponses rapides',
        the_number_of_cases_was_not_timely_replied: 'Nombre de cas en retard de réponse',
        percentage_not_responding_in_time: 'Pourcentage de réponses en retard',
        parts_list: 'Liste de pièces',
        parts_num: 'Anzahl der Teile',
        sales_country: 'Pays de vente',
        continuous_interval: 'Intervalle continu',
        cycle: 'Cycle',
        growth_threshold: 'Seuil croissance',
        tech_inquiry_check: 'Liste de contrôle technique',
        failed_part: 'Pièces affectées',
        symptoms: 'Défaut constaté',
        fault_cause: 'Cause du défaut',
        closing_statement: 'Note de clôture',
        invoice_number: 'Numéro de facture',
        primary_system: 'Primäres System',
        secondary_system: 'Système secondaire',
    },
    // 意大利
    itIT: {
        fill_in_at_least_one_query_condition: 'Compilare almeno una condizione di query',
        reporting_organization_name: 'Nome Organizzazione Segnalante',
        full_query: 'Query Completa',
        query_time: 'Tempo di Query',
        organization_code: 'Codice Organizzazione',
        organization_name: 'Nome Organizzazione',
        country: 'Paese',
        number_of_cases: 'Numero totale di casi',
        timely_reply_to_the_number_of_cases: 'Risposta tempestiva al numero di casi',
        prompt_response_percentage: 'Percentuale di risposta tempestiva',
        the_number_of_cases_was_not_timely_replied:
            'Numero di casi che non hanno risposto tempestivamente',
        percentage_not_responding_in_time: 'Percentuale di risposte ritardate',
        parts_list: 'Elenco delle parti',
        parts_num: 'Quantità di parti',
        sales_country: 'Paese di vendita',
        continuous_interval: 'Intervallo continuo',
        cycle: 'Ciclo',
        growth_threshold: 'Soglia crescita',
        tech_inquiry_check: 'Elenco controllo tecnico',
        failed_part: 'Parte difettosa',
        symptoms: 'Sintomi del malfunzionamento',
        fault_cause: 'Causa del malfunzionamento',
        closing_statement: 'Dichiarazione di chiusura',
        invoice_number: 'Numero di fattura',
        primary_system: 'Système primaire',
        secondary_system: 'Sistema secondario',
    },
    // 荷兰
    nlNL: {
        fill_in_at_least_one_query_condition: 'Vul ten minste één query voorwaarde in',
        reporting_organization_name: 'Rapporterende organisatienaam',
        full_query: 'Volledige query',
        query_time: 'Query time',
        organization_code: 'Organisatie code',
        organization_name: 'Organisatie naam',
        country: 'Land',
        number_of_cases: 'Totaal aantal gevallen',
        timely_reply_to_the_number_of_cases: 'Aantal gevallen',
        prompt_response_percentage: 'Percentage tijdige antwoorden',
        the_number_of_cases_was_not_timely_replied:
            'Aantal gevallen waarin niet tijdig is gereageerd',
        percentage_not_responding_in_time: 'Percentage niet-tijdige antwoorden',
        parts_list: 'Lijst van onderdelen',
        parts_num: 'Aantal onderdelen',
        sales_country: 'Verkoopland',
        continuous_interval: 'Continu interval',
        cycle: 'Cyclus',
        growth_threshold: 'Groei drempel',
        tech_inquiry_check: 'Technische controlelijst',
        failed_part: 'Getroffen onderdeel',
        symptoms: 'Foutverschijnselen',
        fault_cause: 'Foutoorzaak',
        closing_statement: 'Omschrijving van de zaak',
        invoice_number: 'Factuurnummer',
        primary_system: 'Primaire systeem',
        secondary_system: 'Secondaire systeem',
    },
    // 越南
    viVN: {
        fill_in_at_least_one_query_condition: 'Ít nhất điền một điều kiện tìm kiếm',
        reporting_organization_name: 'Tên tổ chức báo cáo',
        full_query: 'Tra cứu toàn bộ',
        query_time: 'Thời gian truy vấn',
        organization_code: 'Mã tổ chức',
        organization_name: 'Tên tổ chức',
        country: 'Quốc gia',
        number_of_cases: 'Tổng số trường hợp',
        timely_reply_to_the_number_of_cases: 'Số lượng trả lời kịp thời cho các trường hợp',
        prompt_response_percentage: 'Phần trăm phản hồi kịp thời',
        the_number_of_cases_was_not_timely_replied:
            'Số lượng trường hợp không được trả lời kịp thời',
        percentage_not_responding_in_time: 'Phần trăm không phản hồi kịp thời',
        parts_list: 'Danh sách linh kiện',
        parts_num: 'Số lượng linh kiện',
        sales_country: 'Nước bán hàng',
        continuous_interval: 'Khoảng liên tục',
        cycle: 'Chu kỳ',
        growth_threshold: 'Ngưỡng tăng trưởng',
        tech_inquiry_check: 'Danh sách kiểm tra kỹ thuật',
        failed_part: 'Ảnh hưởng',
        symptoms: 'Hiện tượng lỗi',
        fault_cause: 'Nguyên nhân lỗi',
        closing_statement: 'Lời giải thích kết thúc',
        invoice_number: 'Số hóa đơn',
        primary_system: ' Hệ thống cấp một',
        secondary_system: 'Hệ thống cấp hai',
    },
    // 西班牙
    esES: {
        fill_in_at_least_one_query_condition: 'Al menos complete un criterio de búsqueda',
        reporting_organization_name: 'Nombre de la organización de informes',
        full_query: 'Consulta completa',
        query_time: 'Tiempo de consulta',
        organization_code: 'Código de la organización',
        organization_name: 'Nombre de la organización',
        country: 'País',
        number_of_cases: 'Número total de casos',
        timely_reply_to_the_number_of_cases: 'Número de casos respondidos a tiempo',
        prompt_response_percentage: 'Porcentaje de casos respondidos a tiempo',
        the_number_of_cases_was_not_timely_replied: 'Número de casos no respondidos a tiempo',
        percentage_not_responding_in_time: 'Porcentaje de casos no respondidos a tiempo',
        parts_list: 'Lista de piezas',
        parts_num: 'Cantidad de piezas',
        sales_country: 'País de venta',
        continuous_interval: 'Intervalo continuo',
        cycle: 'Ciclo',
        growth_threshold: 'Umbral crecimiento',
        tech_inquiry_check: 'Lista de verificación técnica',
        failed_part: 'Piezas defectuosas',
        symptoms: 'Síntoma de falla',
        fault_cause: 'Causa de falla',
        closing_statement: 'Explicación de cierre',
        invoice_number: 'Número de factura',
        primary_system: 'Sistema primario',
        secondary_system: 'Sistema secundario',
    },
    // 葡萄牙
    ptBR: {
        fill_in_at_least_one_query_condition: 'Preencha pelo menos uma condição de consulta',
        reporting_organization_name: 'Nome da organização de relatório',
        full_query: 'Consulta completa',
        query_time: 'Tempo de consulta',
        organization_code: 'Código da organização',
        organization_name: 'Nome da organização',
        country: 'País',
        number_of_cases: 'Número total de casos',
        timely_reply_to_the_number_of_cases: 'Número de respostas pontuais aos casos',
        prompt_response_percentage: 'Porcentagem de respostas pontuais',
        the_number_of_cases_was_not_timely_replied: 'Número de casos não respondidos pontualmente',
        percentage_not_responding_in_time: 'Porcentagem de casos não respondidos pontualmente',
        parts_list: 'Lista de peças',
        parts_num: 'Quantidade de peças',
        sales_country: 'País de venda',
        continuous_interval: 'Intervalo contínuo',
        cycle: 'Ciclo',
        growth_threshold: 'Limite crescimento',
        tech_inquiry_check: 'Lista de verificação técnica',
        failed_part: 'Peça com falha',
        symptoms: 'Sintomas',
        fault_cause: 'Causa da falha',
        closing_statement: 'Declaração de encerramento',
        invoice_number: 'Número da fatura',
        primary_system: 'Sistema primário',
        secondary_system: 'Sistema secundário',
    },
    // 波兰
    plPL: {
        fill_in_at_least_one_query_condition: 'Wypełnij co najmniej jeden warunek zapytania',
        reporting_organization_name: 'Nazwa organizacji raportującej',
        full_query: 'Pełne zapytanie',
        query_time: 'Data zapytania',
        organization_code: 'Kod organizacji',
        organization_name: 'Nazwa organizacji',
        country: 'Kraj',
        number_of_cases: 'Łączna liczba spraw',
        timely_reply_to_the_number_of_cases: 'Liczba terminowych odpowiedzi',
        prompt_response_percentage: 'Liczba terminowych odpowiedzi (%)',
        the_number_of_cases_was_not_timely_replied: 'Liczba odpowiedzi nie udzielonych w terminie',
        percentage_not_responding_in_time: 'Liczba odpowiedzi nie udzielonych w terminie (%)',
        parts_list: 'Lista części',
        parts_num: 'Liczba części',
        sales_country: 'Kraj sprzedazy',
        continuous_interval: 'Ciągły przedział',
        cycle: 'Cyklus',
        growth_threshold: 'Prognoza wzrostu',
        tech_inquiry_check: 'Lista kontrolna techniczna',
        failed_part: 'Uszkodzona część',
        symptoms: 'zjawisko usterki',
        fault_cause: 'Przyczyna błędu',
        closing_statement: 'Wyajśnienie końcowe',
        invoice_number: 'Numer faktury',
        primary_system: 'Podstawowy system',
        secondary_system: 'Drugi system',
    },
    // 土耳其
    trTR: {
        fill_in_at_least_one_query_condition: 'En azından bir soru durumunu doldur',
        reporting_organization_name: 'Bildirim organizasyonu adı',
        full_query: 'Tam sorgulama',
        query_time: 'Sorgulama zamanı',
        organization_code: 'Organizasyon CODE',
        organization_name: 'Organizasyon adı',
        country: 'Ülke',
        number_of_cases: 'Toplam dosyalar',
        timely_reply_to_the_number_of_cases: 'Zamanında cevaplanmış dosyalar',
        prompt_response_percentage: 'Hızlı cevaplama yüzdesi',
        the_number_of_cases_was_not_timely_replied: 'Zamanında cevaplanmamış dosya sayısı',
        percentage_not_responding_in_time: 'Zamanında cevaplanmış dosya  yüzdesi',
        parts_list: 'Parça listesi',
        parts_num: 'Parça sayısı',
        sales_country: 'Satış ülkesi',
        continuous_interval: 'Sürekli aralık',
        cycle: 'Döngü',
        growth_threshold: 'Büyüme eşiği',
        tech_inquiry_check: 'Teknik kontrol listesi',
        failed_part: 'Etkilendi',
        symptoms: 'Hata semptomu',
        fault_cause: 'Hata sebebi',
        closing_statement: 'Kapanış bildirimi',
        invoice_number: 'Fatura numarası',
        primary_system: 'Birinci derece sistem',
        secondary_system: 'İkinci derece sistem',
    },
    // 捷克语
    csCZ: {
        fill_in_at_least_one_query_condition: 'Vyplňte alespoň jednu podmínku dotazu',
        reporting_organization_name: 'Název ohlašující společnosti',
        full_query: 'Úplný dotaz',
        query_time: 'Čas dotazu',
        organization_code: 'Kód společnosti',
        organization_name: 'Název společnosti',
        country: 'Země',
        number_of_cases: 'Počet případů',
        timely_reply_to_the_number_of_cases: 'Včasná odpověď na počet případů',
        prompt_response_percentage: 'Procento rychlé odezvy',
        the_number_of_cases_was_not_timely_replied: 'Počet případů které nebyly včas odpovězeny',
        percentage_not_responding_in_time: 'Procento neodpovídá včas',
        parts_list: 'Seznam dílů',
        parts_num: 'Počet dílů',
        sales_country: 'Prodejní země',
        continuous_interval: 'Spojitý interval',
        cycle: 'Cyklus',
        growth_threshold: 'Rostoucí práh',
        tech_inquiry_check: 'Technická kontrolní lista',
        failed_part: 'Systémová část',
        symptoms: 'Fenomén závady',
        fault_cause: 'Příčina závady',
        closing_statement: 'Závěrečná zpráva',
        invoice_number: 'Číslo dokladu',
        primary_system: 'Primární systém',
        secondary_system: 'Sekundární systém',
    },
    // 匈牙利语
    huHU: {
        fill_in_at_least_one_query_condition: 'Töltsön ki legalább egy lekérdezési feltételt',
        reporting_organization_name: 'Jelentő szervezet neve',
        full_query: 'Teljes lekérdezés',
        query_time: 'Lekérdezési idő',
        organization_code: 'Szervezet kódja',
        organization_name: 'Szervezet neve',
        country: 'Ország',
        number_of_cases: 'Esetek száma',
        timely_reply_to_the_number_of_cases: 'Időben megválaszolt esetek száma',
        prompt_response_percentage: 'Azonnali válaszok százaléka',
        the_number_of_cases_was_not_timely_replied: 'Nem időben megválaszolt esetek száma',
        percentage_not_responding_in_time: 'Nem időben történt válaszok százaléka',
        parts_list: 'Részek listája',
        parts_num: 'Részek száma',
        sales_country: 'Eladási ország',
        continuous_interval: 'Folytonos intervallum',
        cycle: 'Ciklus',
        growth_threshold: 'Növekedési határérték',
        tech_inquiry_check: 'Technikai ellenőrzési lista',
        failed_part: 'Rendszer része',
        symptoms: 'Hibajelenség',
        fault_cause: 'Hiba oka',
        closing_statement: 'Lezárási nyilatkozat',
        invoice_number: 'Számlaszám',
        primary_system: 'Első szintű rendszer',
        secondary_system: 'Második szintű rendszer',
    },
    // 俄罗斯语
    ruRU: {
        fill_in_at_least_one_query_condition: 'Заполните хотя бы одно условие запроса',
        reporting_organization_name: 'Название отчетной организации',
        full_query: 'Полный запрос',
        query_time: 'Время запроса',
        organization_code: 'Код организации',
        organization_name: 'Название организации',
        country: 'Страна',
        number_of_cases: 'Общее количество случаев',
        timely_reply_to_the_number_of_cases: 'Количество случаев с своевременным ответом',
        prompt_response_percentage: 'Процент своевременных ответов',
        the_number_of_cases_was_not_timely_replied:
            'Количество случаев, на которые не был дан своевременный ответ',
        percentage_not_responding_in_time:
            'Процент случаев, на которые не был дан своевременный ответ',
        parts_list: 'Список деталей',
        parts_num: 'Количество деталей',
        sales_country: 'Страна продажи',
        continuous_interval: 'Непрерывный интервал',
        cycle: 'Цикл',
        growth_threshold: 'Порог роста',
        tech_inquiry_check: 'Технический контрольный список',
        failed_part: 'Неисправная деталь',
        symptoms: 'Симптом неисправности',
        fault_cause: 'Причина неисправности',
        closing_statement: 'Заключение по делу',
        invoice_number: 'Номер счета-фактуры',
        primary_system: 'Первая система',
        secondary_system: 'Вторая система',
    },
};
